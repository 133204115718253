
import ControllerFooter from "Controllers/ControllerFooter";
export default {
  components: {
    ControllerFooter,
  },
  data() {
    return {
      inView: false,
      infoLinks: [
        {
          name: "title",
          label: "Ime* (ako zelite bez linka ovo polje je obvezno)",
          type: "text",
        },
        {
          name: "icon",
          label: "Icon class",
          type: "text",
        },
        {
          name: "link",
          label: "Poveznica",
          type: "link",
        },
      ],
      socialOptions: [
        {
          name: "icon",
          label: "Icon class",
          type: "text",
        },
        {
          name: "link",
          label: "Poveznica",
          type: "link",
        },
      ],
      privacyOptions: [
        {
          name: "link",
          label: "Poveznica",
          type: "link",
        },
      ],
    };
  },
};
