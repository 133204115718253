
import SupportHeroSearch from "./support-hero-search.vue";
import VerticalTabs from "./vertical-tabs.vue";
export default {
  components: { SupportHeroSearch, VerticalTabs },
  data() {
    return {
      posts: [],
      pageData: null,
      searchTerm: "",
      categoryId: null,
      options: {
        page: 1,
        itemsPerPage: 11,
      },
    };
  },
  head() {
    return {
      bodyAttrs: {
        class: ["image-bg"],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.postCategory && this.$refs.postCategory.category) {
        this.categoryId = this.$refs.postCategory.category.id;
        this.getPages();
      }
    }, 300);
  },
  methods: {
    async supportSearch() {
      await this.$router.push({
        path: "/search-results",
        query: {
          posttype: this.pageData.type.slug,
          keyword: this.searchTerm,
        },
      });
    },
    async getPages() {
      if (this.$i18n.locale === "hr") {
        this.posts = await this.$getCustomPost([this.categoryId]);
      } else this.posts = await this.$getCustomPost([this.$route.params.slug]);

      this.pageData = await this.$getPageData(this.posts[0].slug);
    },
  },
};
