
export default {
  props: {
    inputClasses: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    labelFor: {
      type: String,
      default: "",
    },
    withBtn: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showErrorMsg: {
      type: Object,
    },
    customForm: {
      type: Boolean,
      default: false,
    },
    questionnaireError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileName: "",
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    onFileChange(event) {
      if (this.type === "file") {
        const fileData = event.target.files[0];
        if (fileData && fileData.name) {
          this.fileName = fileData.name;
        }
      }
    },
  },
};
