
import ControllerPostList from "Controllers/ControllerPostList";
export default {
  components: {
    ControllerPostList,
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 11,
      },
    };
  },
  head() {
    return {
      bodyAttrs: {
        class: ["image-bg"],
      },
    };
  },
};
