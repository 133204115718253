import { render, staticRenderFns } from "./ErrorView.vue?vue&type=template&id=7f0f4ce7&scoped=true"
var script = {}
import style0 from "./ErrorView.vue?vue&type=style&index=0&id=7f0f4ce7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f0f4ce7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Btn: require('/home/node/app/components/ui/btn.vue').default,ControllerError: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerError.js').default})
