
export default {
  props: {
    availableLocales: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      openLang: false,
    };
  },
  methods: {
    emitLang(value) {
      this.openLang = false;
      this.$emit("switch-lang", value);
    },
  },
};
