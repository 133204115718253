
export default {
  props: {
    posts: {
      type: Array,
    },
    pageData: {
      type: Object,
    },
  },
  data() {
    return {
      pages: null,
      category: null,
      categories: null,
      categoryId: null,
    };
  },
  mounted() {
    if (!this.pageData) {
      this.getPageData();
    }
  },
  methods: {
    async getPageData() {
      this.categories = await this.$getCategoriesInGroup("Helpdesk");
      if (this.categories && this.categories.length) {
        this.categories.forEach((category) => {
          if (category.slug === this.$route.params.slug) {
            this.categoryId = category.id;
          }
        });
      }
      if (this.categoryId) {
        this.pages = await this.$getCustomPost([this.categoryId]);
        this.category = this.pages[0];
      }
    },
  },
};
