
export default {
  name: "Btn",
  props: {
    size: {
      type: String,
      required: false,
    },
    bg: {
      type: String,
      required: false,
    },
    link: {
      type: [String, Object],
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
};
