import { render, staticRenderFns } from "./articles.vue?vue&type=template&id=0c8c102e"
import script from "./articles.vue?vue&type=script&lang=js"
export * from "./articles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiPost: require('/home/node/app/components/ui/uiPost.vue').default,ControllerPostList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js').default})
