
import ControllerPostList from "Controllers/ControllerPostList";
export default {
  components: {
    ControllerPostList,
  },
  data() {
    return {
      blogCategories: [],
      options: {
        page: 1,
        itemsPerPage: 11,
      },
    };
  },
  head() {
    return {
      bodyAttrs: {
        class: ["image-bg"],
      },
    };
  },
  mounted() {
    this.getBlogCategories();
  },
  methods: {
    async getBlogCategories() {
      const blogCategories = await this.$getCategoriesInGroup("Blog");
      this.blogCategories = blogCategories.map((x) => x.slug);
    },
  },
};
