
export default {
  props: {
    helpdesk: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: true,
    },
    pageData: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      searchTerm: "",
    };
  },
  methods: {
    async supportSearch() {
      await this.$router.push({
        path: "/search-results",
        query: {
          posttype: this.pageData.type.slug,
          keyword: this.searchTerm,
        },
      });
    },
  },
};
