
export default {
  data() {
    return {
      mobileMenu: false,
      visibleSubmenuIndex: null,
      mobileView: false,
    };
  },
  watch: {
    $route: {
      handler() {
        this.mobileMenu = false;
        this.visibleSubmenuIndex = null;

        if (process.browser) {
          document.body.classList.remove("modal-html-open");
          document.body.classList.remove("modal-html-open");
        }
      },
    },
    mobileMenu: {
      handler(val) {
        if (val) {
          document.body.classList.add("modal-html-open");
        } else {
          document.body.classList.remove("modal-html-open");
        }
        const el = document.getElementById("mobileMenu");
        if (el) {
          el.scrollTo(0, 0);
        }
      },
    },
  },
  mounted() {
    window.addEventListener("resize", this.closeMobileMenu, {
      passive: true,
    });
  },
  methods: {
    openSubMenu(index) {
      if (this.visibleSubmenuIndex) {
        this.visibleSubmenuIndex = null;
      } else {
        this.visibleSubmenuIndex = index;
      }
    },

    closeDesktopMenu() {
      setTimeout(() => {
        this.visibleSubmenuIndex = null;
      }, 900);
    },
    closeMobileMenu() {
      this.mobileMenu = false;
    },
    openMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
  },
};
