import { render, staticRenderFns } from "./support-hero-search.vue?vue&type=template&id=91170fa2&scoped=true"
import script from "./support-hero-search.vue?vue&type=script&lang=js"
export * from "./support-hero-search.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91170fa2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiInput: require('/home/node/app/components/ui/uiInput.vue').default})
