
export default {
  props: {
    image: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: true,
    },
    size: {
      type: String,
    },
    bg: {
      type: String,
      default: "white",
    },
    item: {
      type: Object,
      required: false,
    },
    index: {
      type: Boolean,
      default: false,
    },
    indexNumber: {
      type: Number,
    },
    keyword: {
      type: String,
    },
  },
  data() {
    return {
      sentences: "",
      sentenceWithKeyword: "",
    };
  },
  methods: {
    partText(htmlText) {
      const tempDivElement = document.createElement("div");

      tempDivElement.innerHTML = htmlText;

      const text =
        tempDivElement.textContent || tempDivElement.textContent || "";

      this.sentences = text.split(/[\\.!?]/);

      this.sentences.forEach((sentence) => {
        if (sentence.includes(this.keyword)) {
          this.sentenceWithKeyword = sentence;
        }
      });
      if (this.sentenceWithKeyword) {
        return this.changeColorOfKeyword(this.sentenceWithKeyword);
      } else return this.sentenceWithKeyword;
    },
    changeColorOfKeyword(text) {
      return text.replace(
        this.keyword,
        `<h2 class="inline-block text-primary-dark-orange underline font-bold">${this.keyword}</h2>`
      );
    },
  },
};
